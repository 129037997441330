<template>
  <div class="main">
    <div class="packet-image" id="package-image">
      <img src="@/assets/packet1.png" alt="" class="snakin" id="snakin-packet">
      <div class="customImage" id="customImage"></div>
      <div class="flavor">
        <p class="flavor-text" id="flavor-text">{{flavor}}</p>
      </div>
    </div>

    <div class="controls">
      <div class="color"><div class="thumb" @click="show=!show; showInpirations = false"></div> Color
        <div class="color-grid" :class="show? '':'hide'">
          <div v-for="i in 9" class="item" :key="i" :id="`grid${i}`" @click="handleColor(i)"></div>
        </div>
      </div>
      <input type="text" class="nombre" @change="handleFlavor" placeholder="Nombre del sabor">
      <input type="file" id="image-input" accept="image/png">
      <div class="upload" @click="handleUpload">
        <img src="@/assets/arrow-up.png" alt="">Subir .PNG
      </div>
      <div class="inspiración">
        <p class="inspiración-text" @click="showInpirations=!showInpirations; show = false">Inspiración de sabores</p>
        <div class="inspirationImages" v-if="showInpirations" @click="">
          <img :src="require(`@/assets/${image}`)" v-for="(image,index) in inspirationImages" :key="index" @click="handleInspirations(index)" class="img-inspiration">
        </div>
      </div>
    </div>
    <div id="ins-space" v-if="showInpirations"></div>
  </div>
</template>


<script>
export default {
  name: "HomeView",
  data() {
    return {
      show: false,
      showFlavor: true,
      showInpirations: false,
      inSpace: false,
      inspirationImages: ["chocolate_sabor.png", "galletas_sabor.png", "naranja_sabor.png",
        "patatas_sabor.png", "aguacate_sabor.png", "salsabbq_sabor.png", "mostaza_sabor.png",
        "humus_sabor.png", "gazpacho_sabor.png"],
      flavor: "",
      thumbColor:'#efdc00',
      currentPacket: require(`@/assets/packet1.png`),
      currentUploadedImage: require('@/assets/patatas_sabor.png'),
      banNamed:['Pene','Polla','PichaPichula','Cipote','Verga','Vergota','Nardo','Nabo','Rabo','Cola','Coño','Papo','Chocho','Chichi','Clítoris','Figa','Teta','Lefa','Esmegma','Semen','Prepucio','Escroto','Huevo','Testículo','Vagina','Coñamen','Dildo','Satisfyer','Ano','Culo','Consolador','Paja','Gayola','Gallola','Regla','Menstruación','Negro','Mamada','Corrida','Puta','Mierda','Caca','Pis','Orina','Meada']
    };
  },
  computed: {},
  methods: {
    downloadPackage(){

    },
    updateLocalStorage(){
      window.localStorage.setItem('thumbColor',this.thumbColor)
      window.localStorage.setItem('packetImage',this.currentPacket)
      window.localStorage.setItem('snakinFlavor',this.flavor)
      window.localStorage.setItem('uploadImage',this.currentUploadedImage)
    },
    handleFlavor(){
      let exist = false;
      let temp = event.target.value;
      for(let i=0; i<this.banNamed.length; i++){
        exist = this.banNamed[i].toLowerCase() === temp.toLowerCase() || this.banNamed[i].toLowerCase() + 's' === temp.toLowerCase()
        if(exist){
          alert('Nombre no válido')
          break;
        }
      }
      !exist? this.flavor = temp : '';
      this.updateLocalStorage()
    },
    handleColor(index) {
      this.show= false
      const getStyle = (element, property) => window.getComputedStyle ? window.getComputedStyle(element, null).getPropertyValue(property) : element.style[property.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); })];
      document.querySelector(".thumb").style.background = getStyle(event.target, "background-color");
      this.currentPacket = require(`@/assets/packet${index}.png`)
      document.getElementById("snakin-packet").src = this.currentPacket;

      this.thumbColor = getStyle(event.target, "background-color")
      //local storage update
      this.updateLocalStorage()
    },
    handleInspirations(index) {
      const imageSrc = require(`@/assets/${this.inspirationImages[index]}`);
      document.getElementById("customImage").style.backgroundImage = `url(${imageSrc})`;
      this.showInpirations = !this.showInpirations;
      this.currentUploadedImage = imageSrc

      if(index==0 || index == 1){document.getElementById(`grid${4}`).click()}
      if(index==2){document.getElementById(`grid${6}`).click()}
      if(index==3){document.getElementById(`grid${1}`).click()}
      if(index==4){document.getElementById(`grid${9}`).click()}
      if(index==5){document.getElementById(`grid${7}`).click()}
      if(index==6){document.getElementById(`grid${1}`).click()}
      if(index==7){document.getElementById(`grid${1}`).click()}
      if(index==8){document.getElementById(`grid${7}`).click()}

    },
    handleUpload() {
      document.querySelector("#image-input").click();
    }
  },
  mounted() {
    const image_input = document.querySelector("#image-input");
    let that = this;
    image_input.addEventListener("change", function () {
      const reader = new FileReader();
      reader.addEventListener("load", function() {
        const uploaded_image = reader.result;
        document.querySelector("#customImage").style.backgroundImage = `url(${uploaded_image})`;
        that.currentUploadedImage  = uploaded_image
        that.updateLocalStorage()
      });
      reader.readAsDataURL(this.files[0]);
    });

    const snakinFlavor = window.localStorage.getItem('snakinFlavor')
    const uploadImage = window.localStorage.getItem('uploadImage')
    const packetImage = window.localStorage.getItem('packetImage')
    const thumbColor = window.localStorage.getItem('thumbColor')
    snakinFlavor? this.flavor =  snakinFlavor : this.flavor =  '';

    if(uploadImage){this.currentUploadedImage = uploadImage}
    document.getElementById("customImage").style.backgroundImage = `url(${this.currentUploadedImage})`;

    if(thumbColor){this.thumbColor = thumbColor}
    document.querySelector(".thumb").style.background=this.thumbColor;

    if(packetImage){this.currentPacket = packetImage}
    document.getElementById("snakin-packet").src = this.currentPacket;

  },
}
</script>

<style lang="scss" scoped>

.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: rgba(0, 0, 0, 0.03);

  .packet-image{
    position: relative;
    #snakin-packet{
      @media only screen and (max-width: 1280px){
        width: 960px;
      }
      @media only screen and (max-width: 980px){
        width: 760px;
      }
      @media only screen and (max-width: 780px){
        width: 560px;
      }
      @media only screen and (max-width: 580px){
        width: 340px;
      }
    }
  }
  #ins-space{
    padding-bottom: 300px;
    @media only screen and (max-width: 780px){
      padding-bottom: 0;
    }
  }
}
#image-input{
  display: none;
}
.flavor{
  transform: rotate(8deg);
  position: absolute;
  bottom: 380px;
  right: 450px;
  font-size: 33px;

  @media only screen and (max-width: 1280px){
    bottom: 396px;
    right: 469px;
  }
  @media only screen and (max-width: 980px){
    bottom: 317px;
    right: 373px;
  }
  @media only screen and (max-width: 780px){
    font-size: 24px;
    bottom: 235px;
    right: 272px;
  }
  @media only screen and (max-width: 580px){
    font-size: 15px;
    bottom: 144px;
    right: 166px;
  }
}
.flavor-text{
  font-family: 'Burbank Big Condensed';
  color: #8e518a;
  position: absolute;
  left: 0;
  top: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 40px;
  width: 145px;
  overflow: hidden;
  text-align: center;
  @media only screen and (max-width: 980px){
    height: 35px;
    width: 120px;
  }
  @media only screen and (max-width: 780px){
    height: 28px;
    width: 85px;
  }
  @media only screen and (max-width: 580px){
    height: 16px;
    width: 50px;
  }
}
.customImage{
  width: 180px;
  height: 213px;
  clip-path: polygon(98% 0, 95% 30%, 91% 55%, 88% 75%, 125% 100%, 0 100%, 0 0);
  position: absolute;
  bottom: 160px;
  right: 98px;

  background-image: url('@/assets/patatas_sabor.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 1280px){
    bottom: 165px;
    right: 105px;
    clip-path: polygon(98% 0, 95% 30%, 91% 53%, 89% 70%, 135% 110%, 0 110%, 0 0);
  }
  @media only screen and (max-width: 980px){
    width: 160px;
    height: 180px;
    bottom: 126px;
    right: 81px;
  }
  @media only screen and (max-width: 780px){
    width: 122px;
    height: 142px;
    bottom: 92px;
    right: 59px;
  }
  @media only screen and (max-width: 580px){
    width: 74px;
    height: 89px;
    bottom: 57px;
    right: 36px;
  }
}
.controls{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-bottom: 75px;
  padding: 0 24px;
  @media only screen and (max-width: 1280px){
    font-size: 32px;
  }
  @media only screen and (max-width: 980px){
    font-size: 24px;
  }
  @media only screen and (max-width: 780px){
    font-size: 12px;
    padding: 0 10px;
  }
  &>*{
    margin-right: 50px;
    margin-bottom: 10px;
    width: max-content;
    cursor: pointer;
    @media only screen and (max-width: 780px){
      margin-right: 20px;
    }
  }
  &>*:last-child{margin-right: 0;}
  .img-inspiration{
    max-width: 165px;
    max-height: 150px;
  }
  .color{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .thumb{
      width: 50px;
      height: 50px;
      background-color: #efdc00;
      margin-right: 20px;
      @media only screen and (max-width: 1080px){
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
      @media only screen and (max-width: 580px){
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .color-grid{
      position: absolute;
      z-index: 999;
      top: 60px;
      left:0;
      padding: 4px;
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-gap: 4px;
      @media only screen and (max-width:780px){
        top: 35px;
      }
      .item{
        width: 50px;
        height: 50px;
        background-color: #FF3A4C;
        cursor: pointer;
        @media only screen and (max-width:580px){
          width: 30px;
          height: 30px;
        }
        &:active{
          border: 1px solid #000;
        }
      }

      #grid1{background: #efdc00;}
      #grid2{background: #6673be;}
      #grid3{background: #cecece;}
      #grid4{background: #612f14;}
      #grid5{background: #982d7f;}
      #grid6{background: #fe5c00;}
      #grid7{background: #cb0410;}
      #grid8{background: #c55eb5;}
      #grid9{background: #008f26;}
    }
    .hide{
      display: none;
    }
  }
  .nombre{
    width: 300px;
    font-family: 'Burbank Big Condensed';
    font-size: 35px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    outline: none;
    border: none;
    padding: 10px 15px;
    background: #D9D9D9;
    caret-color: #000;
    @media only screen and (max-width: 1280px){
      width: 200px;
      font-size: 24px;
    }
    @media only screen and (max-width: 1080px){
      width: 120px;
      font-size: 18px;
    }
    @media only screen and (max-width: 580px){
      width: 120px;
      font-size: 14px;
    }
    @media only screen and (max-width: 390px){
      width: 80px;
      font-size: 12px;
    }

  }

  .upload{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: #D9D9D9;
    transition: all .25s;

    @media only screen and (max-width: 780px){
      padding: 10px;
    }
    img{
      width:30px;
      margin-right: 20px;
      @media only screen and (max-width: 1080px){
        width: 10px;
        margin-right: 10px;
      }
    }
    &:active{
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
    }
  }
  .inspiración{
    position: relative;
    padding: 10px 30px;
    background: #D9D9D9;
    transition: all .25s;
    &:active{
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
    }
    @media only screen and (max-width:1080px){
      padding: 10px;
    }
    @media only screen and (max-width:580px){
      width: min-content;
      padding: 5px;
    }

    .inspirationImages{
      width: max-content;
      max-width: 1320px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 5px;

      position: absolute;
      z-index: 999;
      top: 90px;
      right: 0;
      @media only screen and (max-width:1340px){
        max-width: 1140px;
      }
      @media only screen and (max-width:1180px){
        max-width: 980px;
      }
      @media only screen and (max-width:1080px){
        max-width: 940px;
      }
      @media only screen and (max-width:980px){
        max-width: 740px;
      }
      @media only screen and (max-width:780px){
        max-width: 540px;
        top: 50px;
      }
      @media only screen and (max-width:580px){
        max-width: 340px;
      }
      &>*:last-child{
        margin-right: 0px;
      }
      img{
        height: 150px;
        margin-right: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(24px);
        padding: 5px;
        margin-bottom: 15px;
        cursor: pointer;
        @media only screen and (max-width:1080px){
          height: 100px;
        }
        @media only screen and (max-width:780px){
          height: 70px;
          margin-right: 10px;
        }
        @media only screen and (max-width:580px){
          height: 45px;
          margin-right: 5px;
        }
        &:active{
          border: 2px solid #000;
        }
      }

      &>*:last-child{
        margin-right: 0px;
      }

    }
  }
}


</style>