<template>
  <router-view/>
</template>

<style lang="scss">
// FONTS
@font-face {
  font-family: 'Burbank Big Condensed';
  src: local("Burbank Big Condensed"), url(./fonts/Burbank-Big-Condensed-Black-700.ttf) format("truetype");
}
@font-face {
  font-family: 'Dirty Headline';
  src: local("Dirty Headline"), url(./fonts/Dirty\ Headline.ttf) format("truetype");
}
@font-face {
  font-family: 'Catamaran';
  src: local("Catamaran"), url(./fonts/Catamaran-VariableFont_wght.ttf) format("truetype");
}


*,*::after,*::before{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html{
  font-family: 'Burbank Big Condensed';
}
.row{
  width: 100%;
  max-width: 1350px;
  margin-left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width:1180px){
    padding: 0 24px;
  }
}
</style>
