<template>
    <div class="header">
        <img src="@/assets/logo-snackin.png" alt="">
    </div>
    <div class="content row">
      <h1 class="heading">¡Diseña tu propio SNACK’IN Loco!</h1>
      <p class="snippet">Y consigue un smartphone, una mochila pro para tu portátil o una tableta gráfica de última generación.</p>
      <ul class="items row">
        <li class="item">1. Diseña tu SNACK’IN Loco</li>
        <li class="item">2. Haz una captura de pantalla</li>
        <li class="item">3. Súbelo a Twitter mencionando a @Campofrio_es y con #SNACKINLocos</li>
      </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(270deg, #5A5DA4 0%, #FF3A4C 100%);
    
    img{
      padding: 75px 0;
      width: 450px;
      @media only screen and (max-width:1180px){
        width: 340px;
        padding: 55px 0;
      }
      @media only screen and (max-width:580px){
        width: 300px;
        padding: 45px 0;
      }
    }
  }
  .content{
    max-width: 1150px;
    font-weight: 700;
    text-align: center;
    color: #5A5DA4;
    margin-top: 85px;
    margin-bottom: 86px;
    @media only screen and (max-width:580px){
      margin-top: 55px;
      margin-bottom: 55px;
    }
    .heading{
      font-size: 96px;
      margin-bottom: 52px;
      @media only screen and (max-width:1180px){
        font-size: 66px;
        margin-bottom: 32px;
      }
      @media only screen and (max-width:580px){
        font-size: 36px;
        margin-bottom: 24px;
      }
    }

    .snippet{
      font-size: 48px;
      margin-bottom: 58px;
      @media only screen and (max-width:1180px){
        font-size: 36px;
        margin-bottom: 32px;
      }
      @media only screen and (max-width:580px){
        font-size: 20px;
        margin-bottom: 28px;
      }
    }
    .items{
      max-width: 750px;
      list-style: none;
      .item{
        font-family: 'Catamaran';
        line-height: 52px;
        font-size: 32px;
        @media only screen and (max-width:1180px){
          line-height: 32px;
          font-size: 24px;
        }
        @media only screen and (max-width:580px){
          line-height: 20px;
          font-size: 16px;
        }
      }
    }
  }

</style>