<template>
  <div class="footer">
    <p class="text">Si te quedaste con ganas de más</p>
    <a href="https://promosnackin.com/" target="_blank" class="promo-link">
      <p class="round-text">Consigue un set up gamer valorado en 2.000€</p>
    </a>
    <div class="curved-bg"></div>
    <img src="@/assets/set_trade.png" class="seat-img" alt="">
    <a href="/BBLL_Campofrio_Snackin_Locos_2022.pdf" target="_blank" class="bases-legales">Bases legales</a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer{
    position: relative;
    padding-top: 104px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
    @media only screen and (max-width:600px){
        padding-bottom: 80px;
    }
    .text{
        font-family: 'Catamaran';
        font-weight: 700;
        font-size: 48px;
        color: #FF3A4C;
        margin-bottom: 56px;
        text-align: center;
        padding: 0 24px;
        @media only screen and (max-width:980px){
            font-size: 28px;
            margin-bottom: 36px;
        }
    }

    .round-text{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FF3A4C;
        border-radius: 136px;
        font-family: 'Burbank Big Condensed';
        font-weight: 700;
        font-size: 48px;
        text-align: center;
        color: #FFFFFF;
        padding: 30px 60px;
        @media only screen and (max-width:980px){
            font-size: 36px;
            margin-bottom: 36px;
        }
        @media only screen and (max-width:780px){
            padding: 20px 40px;
            font-size: 28px;
            margin: 0 20px;
            margin-bottom: 36px;
        }
        @media only screen and (max-width:580px){
            padding: 10px 25px;
            font-size: 18px;
            margin: 0 20px;
            margin-bottom: 36px;
        }
    }
    .seat-img{
        position: relative;
        z-index: 100;
        width: 600px;
        margin-top: 200px;
        @media only screen and (max-width:980px){
            width: 500px;
            margin-top: 100px;
        }
        @media only screen and (max-width:600px){
            width: 280px;
            margin-top: 100px;
        }
    }

    .curved-bg{
        width: 100%;
        height: 900px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(270deg, #5A5DA4 0%, #FF3A4C 100%);
        clip-path: polygon(0 50%, 100% 27%, 100% 100%, 0% 100%);
        @media only screen and (max-width:980px){
            height: 800px;
        }
        @media only screen and (max-width:600px){
            height: 400px;
            // clip-path: polygon(0 45%, 100% 10%, 100% 100%, 0% 100%);
        }
    }
    .bases-legales{
        position: absolute;
        z-index: 999;
        bottom: 40px;

        font-size: 40px;
        font-family: 'catamaran';
        color: #fff;
        @media only screen and (max-width:600px){
            font-size: 24px;
            bottom: 20px;
           
        }
    }
  .promo-link{
    text-decoration: none;
  }
}


</style>